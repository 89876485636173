import Iconify from "src/components/specific/iconify";
import Typography from "src/components/common/typography";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { TitleKey } from "src/utils/enums";
import { isEmpty } from "src/utils/helps";

import "../styles/form-contact.scss";

interface Props {
  registerForm: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  errors: any;
}

const FormContact = ({ watch, errors, registerForm }: Props) => {
  const values = watch();

  return (
    <div className='contact-info'>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          background: "var(--light-2)",
          padding: "8px 10px",
          borderLeft: "5px solid var(--green)",
        }}
      >
        <Iconify icon='fluent:contact-card-48-filled' />
        <Typography variant='body1'>Thông tin liên hệ</Typography>
      </div>

      <div className='form-wrapper'>
        <div className='form-group'>
          <div className='form-field'>
            <label>Quý danh</label>
            <select required {...registerForm("paxContactInfo.gender")}>
              <option value={TitleKey.MR}>Ông</option>
              <option value={TitleKey.MRS}>Bà</option>
            </select>
          </div>
          <div className='form-field'>
            <div style={{ display: "flex" }}>
              <label>Họ tên</label>
              <Typography style={{ color: "var(--danger)" }}>*</Typography>
            </div>
            <input
              type='text'
              required
              placeholder='Họ tên'
              {...registerForm("paxContactInfo.fullName", { required: true })}
            />
          </div>
          <div className='form-field'>
            <div style={{ display: "flex" }}>
              <label>Điện thoại</label>
              <Typography style={{ color: "var(--danger)" }}>*</Typography>
            </div>
            <input
              type='text'
              required
              placeholder='Điện thoại'
              {...registerForm("paxContactInfo.phoneNumber", {
                required: true,
                pattern: {
                  value: /^[0-9]{10,11}$/,
                  message: "Số điện thoại sai định dạng!",
                },
              })}
            />
            {!isEmpty(errors?.paxContactInfo?.phoneNumber) && (
              <Typography variant='caption' style={{ color: "red" }}>
                {errors?.paxContactInfo?.phoneNumber?.message || ""}
              </Typography>
            )}
          </div>
          <div className='form-field'>
            <div style={{ display: "flex" }}>
              <label>Email</label>
              <Typography style={{ color: "var(--danger)" }}>*</Typography>
            </div>
            <input
              type='text'
              required
              placeholder='Email'
              {...registerForm("paxContactInfo.email", {
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Email sai định dạng!",
                },
              })}
            />
            {!isEmpty(errors?.paxContactInfo?.email) && (
              <Typography variant='caption' style={{ color: "red" }}>
                {errors?.paxContactInfo?.email?.message || ""}
              </Typography>
            )}
          </div>
          <div className='form-field'>
            <label>Địa chỉ</label>
            <input type='text' placeholder='Địa chỉ' {...registerForm("paxContactInfo.address")} />
          </div>
          <div className='form-field'>
            <label>Ghi chú</label>
            <input type='text' placeholder='Yêu cầu đặc biệt' {...registerForm("paxContactInfo.note")} />
          </div>
        </div>

        <div className='form-export__invoice'>
          <div className='form-export__invoice-checkbox'>
            <input
              type='checkbox'
              id='export_invoice'
              checked={values?.exportInvoice || false}
              style={{ cursor: "pointer" }}
              {...registerForm("exportInvoice")}
            />
            <label htmlFor='export_invoice' style={{ cursor: "pointer" }}>
              Tôi muốn xuất hóa đơn
            </label>
          </div>

          {values?.exportInvoice && (
            <div className='form-group'>
              <div className='form-field'>
                <div style={{ display: "flex" }}>
                  <label>Tên công ty</label>
                  <Typography style={{ color: "var(--danger)" }}>*</Typography>
                </div>
                <input
                  type='text'
                  placeholder='Tên công ty'
                  {...registerForm("bookingInvoice.companyName", { required: true })}
                />
              </div>
              <div className='form-field'>
                <div style={{ display: "flex" }}>
                  <label>Địa chỉ</label>
                  <Typography style={{ color: "var(--danger)" }}>*</Typography>
                </div>
                <input
                  type='text'
                  placeholder='Địa chỉ'
                  {...registerForm("bookingInvoice.address", { required: true })}
                />
              </div>
              <div className='form-field'>
                <div style={{ display: "flex" }}>
                  <label>Thành phố</label>
                  <Typography style={{ color: "var(--danger)" }}>*</Typography>
                </div>
                <input
                  type='text'
                  placeholder='Thành phố'
                  {...registerForm("bookingInvoice.cityName", { required: true })}
                />
              </div>
              <div className='form-field'>
                <div style={{ display: "flex" }}>
                  <label>Mã số thuế VAT</label>
                  <Typography style={{ color: "var(--danger)" }}>*</Typography>
                </div>
                <input
                  type='text'
                  placeholder='Mã số thuế VAT'
                  {...registerForm("bookingInvoice.taxCode", { required: true })}
                />
              </div>
              <div className='form-field'>
                <div style={{ display: "flex" }}>
                  <label>Người nhận hóa đơn</label>
                  <Typography style={{ color: "var(--danger)" }}>*</Typography>
                </div>
                <input
                  type='text'
                  placeholder='Người nhận hóa đơn'
                  {...registerForm("bookingInvoice.receiver", { required: true })}
                />
              </div>
              <div className='form-field'>
                <label>Email</label>
                <input type='text' placeholder='Email' {...registerForm("bookingInvoice.email", { required: true })} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormContact;
