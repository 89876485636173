import Iconify from "src/components/specific/iconify";
import Typography from "src/components/common/typography";
import { convertTime, convertToCommasFormat, isEmpty } from "src/utils/helps";
import { FlightOption } from "src/interfaces/book-flight-ticket-interface";
import { AirlineImage, BagValue, Customer, FormatTime } from "src/utils/enums";
import { useFlightContext } from "src/context/flight-context";
import { useMemo } from "react";

import "../styles/price-summary.scss";

const PriceSummary = () => {
  const { selectedFlight, priceFlightData, watch, bagOptions } = useFlightContext();

  const values = watch();

  const convertBagData = useMemo(() => {
    let data = [];
    values?.paxLists?.forEach((val) => {
      const ancillaryCode = val?.ancillaryCode || {};
      Object.keys(ancillaryCode)?.forEach((value) => {
        if (isEmpty(ancillaryCode?.[value])) return;
        const bagData = bagOptions?.[value];
        data?.push(bagData?.find((item) => item?.code === ancillaryCode?.[value]));
      });
    });
    return data;
  }, [values, bagOptions]);

  const price = useMemo(() => {
    let price = priceFlightData?.all;
    if (isEmpty(convertBagData)) return price;
    convertBagData?.forEach((val) => {
      price += val?.fares?.[0]?.total;
    });
    return price;
  }, [priceFlightData, convertBagData]);

  return (
    <div className='price-summary'>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          color: "var(--white)",
          background: "var(--green)",
          padding: "8px 10px",
        }}
      >
        <Iconify icon='fa:plane' />
        <Typography variant='body1'>Chuyến bay của bạn</Typography>
      </div>
      <div className='price-summary__flights'>
        {selectedFlight?.map((flight: FlightOption, index: number) => (
          <div key={index} className='price-summary__flight'>
            <Typography
              variant='subtitle1'
              style={{ fontWeight: 600 }}
            >{`${flight?.departure?.IATACode || ""} → ${flight?.arrival?.IATACode || ""}`}</Typography>
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Iconify icon='si:clock-fill' />
              <Typography
                style={{ fontWeight: 400 }}
              >{`${convertTime(flight?.departure?.at, FormatTime.HHMM)} ${convertTime(flight?.departure?.at, FormatTime.ddmm)}`}</Typography>
            </div>
            <img src={AirlineImage?.[flight?.airline]} alt={flight.airline} className='price-summary__logo' />
          </div>
        ))}
      </div>

      <div className='price-summary__overview'>
        <ul className='price-summary__overview-header'>
          <li>
            <Typography variant='subtitle1'>Tóm tắt giá vé</Typography>
          </li>
          <li>
            <Typography variant='subtitle1'>Tổng</Typography>
          </li>
        </ul>

        {priceFlightData?.value?.map((val, idx) => (
          <ul className='price-summary__overview-item' key={idx}>
            <li>
              <Typography style={{ fontWeight: 400 }}>{Customer?.[val?.name] || ""}</Typography>
            </li>
            <li>
              <Typography
                style={{ fontWeight: 400 }}
              >{`${val?.quantity} x ${convertToCommasFormat(val?.total)}`}</Typography>
            </li>
            <li>
              <Typography style={{ fontWeight: 400, color: "var(--orange)" }}>
                {convertToCommasFormat(val?.totalAll)}
              </Typography>
            </li>
          </ul>
        ))}

        {convertBagData?.map((val, idx) => (
          <ul className='price-summary__overview-item' key={idx}>
            <li style={{ display: "flex", alignItems: "center" }}>
              <Iconify icon='uis:bag' />
              <Typography style={{ fontWeight: 400 }}>Hành lý ký gửi</Typography>
            </li>
            <li>
              <Typography style={{ fontWeight: 400 }}> {BagValue?.[val?.code]}</Typography>
            </li>
            <li>
              <Typography style={{ fontWeight: 400, color: "var(--orange)" }}>
                {convertToCommasFormat(val?.fares?.[0]?.total)}
              </Typography>
            </li>
          </ul>
        ))}

        <ul className='price-summary__overview-total'>
          <li>
            <Typography variant='subtitle2'>Tổng giá (VND)</Typography>
          </li>
          <li>
            <Typography variant='subtitle2' style={{ color: "var(--orange)" }}>
              {convertToCommasFormat(price || 0)}
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PriceSummary;
