import Container from "src/components/specific/container";
import StepProgress from "../components/step-progess";
import DisplayMode from "../components/display-mode";
import FlightFilter from "../components/flight-filter";
import FlightList from "../components/flight-list";
import FlightFilterMob from "../components/flight-filter-mob";
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  convertItinerariesData,
  convertTime,
  filterByRangeTime,
  findFareOption,
  isEmpty,
  parseFlightString,
  sleep,
} from "src/utils/helps";
import axiosClient from "src/apis/axiosClient";
import Typography from "src/components/common/typography";
import Loading from "src/components/specific/loading/Loading";
import { useBoolean } from "src/hooks/use-boolean";
import Modal from "src/components/specific/modal";
import Iconify from "src/components/specific/iconify";
import { FilterOption, FlightOption, TypedBag } from "src/interfaces/book-flight-ticket-interface";
import domestic from "src/utils/domestic.json";
import { CustomerKey, FareCode, FormatTime, TitleKey, PaymentMethodKey } from "src/utils/enums";
import FlightSelection from "../components/flight-selection";
import PriceSummary from "../components/price-summary";
import InfoFlightIcon from "../components/info-flight-icon";
import OverviewBooking from "../components/overview-booking";
import FormView from "../components/form-view";
import { getStorage, setStorage } from "src/hooks/use-local-storage";
import { BOOKING_KEY } from "src/utils/constants";
import { toast } from "react-toastify";
import { FlightProvider } from "src/context/flight-context";
import { useForm } from "react-hook-form";

import "../styles/book-flight-tickets.scss";

export default function BookFlightTicketsView() {
  const steps = ["Chọn chuyến bay", "Đặt chỗ", "Hoàn tất"];

  const currentStep = getStorage("step") || 1;
  const [searchParams] = useSearchParams();
  const request = searchParams.get("Request");
  const airline = searchParams.get("Airline");
  const notFound = useBoolean();
  const isLoadGetBag = useBoolean();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);
  const [flightData, setFlightData] = useState<FlightOption[]>([]);
  const [bagsData, setBagsData] = useState<TypedBag>(null);
  const [selectedFlight, setSelectedFlight] = useState<FlightOption[]>([]);
  const [filter, setFilter] = useState<FilterOption>({
    displayMode: FareCode.total,
    sortByAirline: ["all"],
    departureTime: { start: 0, end: 24 },
    arrivalTime: { start: 0, end: 24 },
    directFlight: true,
  });

  const sessionData: any = useMemo(() => {
    return parseFlightString(request, airline);
  }, [request, airline]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      flightType: sessionData?.type || "OW",
      paxLists: [
        {
          firstName: "",
          lastName: "",
          birthday: "",
          paxType: "",
          PaxDocuments: [],
          paxId: "",
          paxLocalIndex: 1,
          title: TitleKey.MR,
          ancillaryCode: {
            ow: "",
            rt: "",
          },
        },
      ],
      paymentType: PaymentMethodKey.QR,
      paxContactInfo: { gender: TitleKey.MR, fullName: "", phoneNumber: "", email: "", note: "", address: "" },
      bookingInvoice: {
        companyName: "",
        address: "",
        cityName: "",
        taxCode: "",
        receiver: "",
        email: "",
      },
      exportInvoice: false,
    },
  });

  const values = watch();

  const handleFilterData = useCallback(
    (data: FlightOption[]) => {
      const filterAirline = [...data?.filter((ite) => filter?.sortByAirline.indexOf(ite?.airline) > -1)];
      return filterByRangeTime(
        filterAirline,
        filter?.departureTime?.start,
        filter?.departureTime?.end,
        filter?.arrivalTime?.start,
        filter?.arrivalTime?.end
      );
    },
    [filter]
  );

  const initialFlightData = useMemo(() => {
    try {
      const isRT = !!sessionData?.flights?.[0]?.returnDate;
      let session = [
        {
          arrival: "",
          ...sessionData?.flights?.[0],
        },
      ];
      const { departDate, ...arrivalData } = sessionData?.flights?.[0];
      isRT &&
        session.push({
          ...arrivalData,
          arrival: "arrival",
          startPoint: arrivalData?.endPoint,
          endPoint: arrivalData?.startPoint,
          departDate: arrivalData?.returnDate,
        });

      const newData = handleFilterData(flightData);

      if (isEmpty(flightData) || !filter?.directFlight) return { departure: [], arrival: [], isRT: false, session };
      let departure = [];
      let arrival = [];

      session?.forEach((value) => {
        newData?.forEach((val) => {
          if (val?.departure?.IATACode === value?.startPoint) {
            if (value?.arrival) {
              return arrival.push(val);
            } else {
              return departure.push(val);
            }
          }
        });
      });

      return { departure, arrival, isRT, session };
    } catch (error) {
      console.log("Error >>>", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightData, sessionData, filter]);

  const listAirline = useMemo(() => {
    let listAirline = [];
    flightData?.forEach((val) => {
      let data = val?.fareOptions?.[0];
      val?.fareOptions?.forEach((item) => {
        if (item?.[FareCode.total] < data?.[FareCode.total]) {
          data = item;
        }
      });
      if (isEmpty(listAirline)) {
        listAirline.push({
          code: val?.airline,
          lowestPrice: data?.[FareCode.total],
        });
      } else {
        const current = listAirline?.findIndex((item) => item?.code === val?.airline);
        if (current < 0) {
          listAirline.push({
            code: val?.airline,
            lowestPrice: data?.[FareCode.total],
          });
        } else {
          val?.fareOptions?.forEach((item) => {
            if (item?.[FareCode.total] < listAirline?.[current]?.lowestPrice) {
              listAirline?.splice(current, 1, {
                code: val?.airline,
                lowestPrice: item?.[FareCode.total],
              });
            }
          });
        }
      }
    });
    return listAirline;
  }, [flightData]);

  const getListAirCode = useMemo(() => {
    let filterArr = ["all"];

    flightData?.forEach((val) => {
      if (filterArr?.indexOf(val?.airline) === -1) {
        filterArr.push(val?.airline);
      }
    });
    return filterArr;
  }, [flightData]);

  const initialText = useMemo(() => {
    const data = sessionData?.flights?.[0];
    const isRT = !!data?.returnDate;
    const start = domestic?.find((val) => val?.code === data?.startPoint);
    const end = domestic?.find((val) => val?.code === data?.endPoint);
    return {
      text: `${start?.city} (${start?.code}) ${isRT ? "⟷" : "➞"} ${end?.city} (${end?.code})`,
      time: `Ngày đi: ${convertTime(data?.departDate, FormatTime.ddmmyyyy)} ${isRT ? "⟷" : ""} ${isRT ? `Ngày về: ${convertTime(data?.returnDate, FormatTime.ddmmyyyy)}` : ""}`,
    };
  }, [sessionData]);

  const priceFlightData = useMemo(() => {
    const price = selectedFlight?.map((flight) => {
      let data = flight?.fareOptions?.[0];
      flight?.fareOptions?.forEach((val) => {
        if (val?.[FareCode.total] < data?.[FareCode.total]) {
          data = val;
        }
      });
      return data;
    });
    const customer = [CustomerKey.adult, CustomerKey.child, CustomerKey.infant];
    const arr = [];
    let total = 0;
    let all = 0;
    customer?.forEach((val) => {
      if (sessionData?.[val] < 1) return;
      price?.forEach((item) => {
        switch (val) {
          case CustomerKey.child:
            total += +item?.totalChild || 0;
            break;
          case CustomerKey.infant:
            total += +item?.totalInfant || 0;
            break;

          default:
            total += +item?.totalAdult || 0;
            break;
        }
      });
      all += total * sessionData?.[val];
      arr.push({
        name: val,
        quantity: sessionData?.[val] || 0,
        total,
        totalAll: total * sessionData?.[val],
        ...price,
      });
    });
    return { value: arr, all };
    // eslint-disable-next-line
  }, [sessionData, selectedFlight, CustomerKey]);

  const handleSelectAgain = useCallback(
    (index: number) => {
      selectedFlight.splice(index, 1);
      setSelectedFlight([...selectedFlight]);
    },
    [selectedFlight]
  );

  const bagOptions = useMemo(() => {
    const bags = bagsData?.ssrBags || [];
    const flightData = sessionData?.flights?.[0];
    let bagOW = [];
    let bagRT = [];
    bags?.forEach((val) => {
      //If Bamboo Airways  => return
      if (val?.airline === "QH") return;
      const start = val?.flights?.split("-")?.[0] || "";
      if (start === flightData?.startPoint) {
        bagOW.push(val);
      } else if (start === flightData?.endPoint) {
        bagRT.push(val);
      }
    });
    if (isEmpty(bagOW) && isEmpty(bagRT)) return null;
    if (flightData?.returnDate) return { ow: bagOW, rt: bagRT };
    return { ow: bagOW };
  }, [bagsData, sessionData]);

  const handleSelectFlight = useCallback(
    (value: FlightOption) => {
      const RTData = initialFlightData?.session?.find((val) => val?.arrival);
      if (value?.departure?.IATACode === RTData?.startPoint) {
        setSelectedFlight([...selectedFlight, value]);
      } else {
        setSelectedFlight([value, ...selectedFlight]);
      }
    },
    [selectedFlight, initialFlightData?.session]
  );

  const handleResetSelectFlight = useCallback(() => {
    setSelectedFlight([]);
  }, []);

  const getBookingCode = useCallback(async (formData) => {
    try {
      setIsLoadingBooking(true);
      const response = await axiosClient.post("/flights/book", formData);
      return response?.data;
    } catch (err: any) {
      setIsLoadingBooking(false);
      toast.error(err?.response?.data?.message);
    }
  }, []);

  const handleBookingFlight = useCallback(
    async (data) => {
      const itinerariesData = selectedFlight?.map((val) =>
        convertItinerariesData(val, sessionData, values?.paxLists, bagOptions)
      );

      const formData = {
        ...data,
        itineraries: itinerariesData,
        contact: 1007016,
        type: selectedFlight?.[0]?.source,
        airlineContact: {
          email: data?.paxContactInfo?.email,
          phoneNumber: data?.paxContactInfo?.phoneNumber,
        },
      };

      !data?.exportInvoice && delete formData?.bookingInvoice;
      !data?.exportInvoice && delete formData?.exportInvoice;

      try {
        const bookingData = await getBookingCode(formData);
        console.log(bookingData, 'bookingData <<<<')
        let getBookingHoldingPNR = await axiosClient.post("/flights/booking/bookingCode", {
          bookingCode: `AD${bookingData?.bookingId}`,
          contact: data?.paxContactInfo?.email,
        });
        console.log(getBookingHoldingPNR, 'getBookingHoldingPNR');
        console.log('GO TO LAST STEP');
        setStorage(BOOKING_KEY, getBookingHoldingPNR?.data);
        setStorage("step", 3);
      } catch(error) {
        toast.error(error?.response?.data?.message);
      }
    setIsLoadingBooking(false);
      
    },
    [selectedFlight, sessionData, getBookingCode, values, bagOptions]
  );

  const getSession = useCallback(async () => {
    try {
      if (isEmpty(sessionData)) return;
      const response = await axiosClient.post("/flights/search", sessionData);
      return response?.data?.data;
    } catch (err: any) {
      throw new Error("getSession error: " + err?.message);
    }
  }, [sessionData]);

  
/**
 * Jamviet.com refactor: thử lại ít nhất 10 lần, mỗi lần cách nhau 1 giây cho tới khi success!
 */
  const getResources = useCallback(async (paramFromApi1: string) => {
    try {
      let attempt = 0;
      const maxRetries = 10;
  
      while (attempt < maxRetries) {
        const response = await axiosClient.get(`/flights/search/${paramFromApi1}`);
  
        if (response?.data?.status === "Succeeded") {
          return response.data; // Thành công, trả về dữ liệu
        }
  
        attempt++;
        if (attempt < maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Chờ 1 giây trước khi thử lại
        }
      }
  
      throw new Error("Max retries reached, status still InProgress");
    } catch (err: any) {
      throw new Error("getResources error: " + err?.message);
    }
  }, []);
  

  const getFlight = useCallback(async (paramFromApi2: string) => {
    try {
      const response = await axiosClient.get(`/flights/search/resources/${paramFromApi2}`);
      return response.data;
    } catch (err: any) {
      throw new Error("getFlight error: " + err?.message);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const api1Data = await getSession();
      const api2Data = await getResources(api1Data);

      if (isEmpty(api2Data?.resources)) return notFound.onTrue();

      api2Data?.resources?.forEach(async (val: string) => {
        const flightRes = await getFlight(val);
        setFlightData((flightData) => [...flightData, ...flightRes?.trips]);
      });
      setIsLoading(false);
    } catch (err: any) {
      notFound.onTrue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightData]);

  const handleGetBag = useCallback(async () => {
    const itineraries = selectedFlight?.map((val) => {
      const fareOption = findFareOption(val?.fareOptions);
      return {
        airline: val?.airline || "",
        arrival: val?.arrival?.IATACode || "",
        arrivalDate: val?.arrival?.at || "",
        bookingClass: isEmpty(fareOption) ? "" : fareOption?.bookingClass || "",
        departure: val?.departure?.IATACode || "",
        departureDate: val?.departure?.at || "",
        fareBasisCode: isEmpty(fareOption) ? "" : fareOption?.fareBasisCode || "",
        fareValue: isEmpty(fareOption) ? "" : fareOption?.fareValue || "",
        flightNumber: val?.flightNumber || "",
        groupClass: isEmpty(fareOption) ? "" : fareOption?.groupClass || "",
        source: val?.source || "",
        itineraryId: val?.segments?.[0]?.segmentValue || "",
        segmentId: val?.segments?.[0]?.segmentId || "",
      };
    });
    const paxList = [];
    sessionData?.adult > 0 && paxList.push({ type: CustomerKey.adult.toUpperCase(), count: sessionData?.adult });
    sessionData?.child > 0 && paxList.push({ type: CustomerKey.child.toUpperCase(), count: sessionData?.child });
    sessionData?.infant > 0 && paxList.push({ type: CustomerKey.infant.toUpperCase(), count: sessionData?.infant });

    const formData = { itineraries, paxList };
    isLoadGetBag.onTrue();
    await axiosClient
      .post(`/flights/list-ancillary`, formData)
      .then((res) => {
        setBagsData(res?.data);
        isLoadGetBag.onFalse();
      })
      .catch((error) => {
        console.log("Get bag error", error);
        isLoadGetBag.onFalse();
      });
  }, [selectedFlight, sessionData, isLoadGetBag]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(getListAirCode)) return;
    setFilter({ ...filter, sortByAirline: [...getListAirCode] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListAirCode]);

  useEffect(() => {
    if (initialFlightData?.session?.length === selectedFlight?.length) {
      setStorage("step", 2);
      !isLoadGetBag.value && handleGetBag();
    } else {
      setStorage("step", 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlight, initialFlightData?.session]);

  if (isEmpty(request) && isEmpty(airline)) return <></>;

  return (
    <FlightProvider
      values={{
        sessionData,
        listAirline,
        filter,
        setFilter,
        priceFlightData,
        selectedFlight,
        request,
        airline,
        bagOptions,
        register,
        handleSubmit,
        watch,
        setValue,
        errors,
      }}
    >
      <Container>
        {isLoading ? (
          <div className='loading-container'>
            <Typography style={{ color: "var(--green)", textAlign: "center" }}>
              Vui lòng đợi trong giây lát, chúng tôi đang tìm kiếm chuyến bay và mức giá tốt nhất cho bạn...
            </Typography>

            <div className='loading-content'>
              <Typography variant='h6'>{initialText?.text}</Typography>
              <Typography style={{ color: "var(--secondary)" }}>{initialText?.time}</Typography>
            </div>
            <Loading />
          </div>
        ) : (
          <div id='BookFlightTicketsView' className='book-flight__container'>
            <div>
              <StepProgress steps={steps} currentStep={currentStep} />

              {currentStep === 3 ? (
                <OverviewBooking />
              ) : (
                <>
                  {!isEmpty(selectedFlight) && (
                    <FlightSelection selectedFlight={selectedFlight} handleSelectAgain={handleSelectAgain} />
                  )}

                  {initialFlightData?.session?.length === selectedFlight?.length && (
                    <FormView
                      sessionData={sessionData}
                      handleBookingFlight={handleBookingFlight}
                      handleResetSelectFlight={handleResetSelectFlight}
                      isLoading={isLoadingBooking}
                    />
                  )}

                  {initialFlightData?.session?.length > selectedFlight?.length && <FlightFilterMob />}

                  {initialFlightData?.session?.map((val, idx) => {
                    if (selectedFlight?.findIndex((item) => item?.departure?.IATACode === val?.startPoint) !== -1)
                      return null;

                    return (
                      <FlightList
                        key={idx}
                        flight={val}
                        flightData={val?.arrival ? initialFlightData?.arrival : initialFlightData?.departure}
                        handleSelectFlight={handleSelectFlight}
                      />
                    );
                  })}
                </>
              )}
            </div>
            {currentStep < 3 && (
              <div className='filter'>
                <div className='filter-container'>
                  {!isEmpty(selectedFlight) && <PriceSummary />}

                  {initialFlightData?.session?.length > selectedFlight?.length && (
                    <>
                      <DisplayMode />

                      <FlightFilter />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {!isEmpty(selectedFlight) && currentStep < 3 && <InfoFlightIcon />}

        <Modal isOpen={notFound.value} onClose={notFound.onFalse}>
          <div style={{ display: "grid", gap: "10px" }}>
            <Typography variant='subtitle2'>Chúng tôi không tìm thấy chuyến bay nào theo yêu cầu</Typography>
            <div style={{ margin: "0 auto" }}>
              <button className='back-btn' onClick={() => window.location.reload()}>
                <Iconify icon='simple-line-icons:reload' />
                <Typography variant='button'>Quay lại</Typography>
              </button>
            </div>
          </div>
        </Modal>
      </Container>
    </FlightProvider>
  );
}
