import PropTypes from "prop-types";
import { ReactNode } from "react";
import { BreakPoint } from "src/utils/enums";

interface Props {
  children?: ReactNode;
  maxWidth?: any;
  style?: object;
}

const Container = ({ children, maxWidth = BreakPoint.xl, style = {}, ...props }: Props) => {
  return (
    <div
      style={{
        maxWidth: maxWidth,
        margin: "0 auto",
        width: "100%",
        padding: "20px 0",
        boxSizing: "border-box",
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  style: PropTypes.object,
};

export default Container;
