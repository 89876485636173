export const BASEURL = process.env.REACT_APP_BASE_URL;

export const LANGUAGE_DROPDOWN_DATA = [
  {
    name: "Tiếng Việt",
    code: "vi",
  },
  {
    name: "English",
    code: "en",
  },
];

export const BOOKING_KEY = "booking_data";
export const INFO_PAYMENT = "info_payment";

export const LANGUAGE_CODE: string[] = ["vi", "en"];
