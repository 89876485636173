import PageNotFound from "./components/specific/pageNotFound/PageNotFound";

import { Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/main";
import { BookFlightTicketsView } from "./pages/book-flight-tickets";
import { HomeView } from "./pages/home/views";

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<HomeView />}></Route>
        <Route path={process.env.REACT_APP_SLUG} element={<MainLayout />}>
          <Route index element={<BookFlightTicketsView />} />
        </Route>

        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
