import "./ranger-slider.scss";

const TimeRangeSlider = ({ initialValue, handleRangeChange, min = 0, max = 24 }) => {
  return (
    <div className='range-slider'>
      <input
        type='range'
        name='start'
        min={min}
        max={max}
        value={initialValue?.[0]}
        onChange={(e) => handleRangeChange(e)}
      />
      <input
        type='range'
        name='end'
        min={min}
        max={max}
        value={initialValue?.[1]}
        onChange={(e) => handleRangeChange(e)}
      />
      <div
        className='slider-track'
        style={{
          left: `${(initialValue[0] / max) * 100}%`,
          right: `${100 - (initialValue[1] / max) * 100}%`,
        }}
      ></div>
    </div>
  );
};

export default TimeRangeSlider;
