import { useMemo } from "react";
import Typography from "src/components/common/typography";
import Iconify from "src/components/specific/iconify";
import { FlightOption } from "src/interfaces/book-flight-ticket-interface";
import { Airline, AirlineImage, Customer, CustomerKey, FareCode, FormatTime, SeatClass } from "src/utils/enums";
import domestic from "src/utils/domestic.json";
import { calculateTimeDifference, convertTime, convertToCommasFormat, isEmpty, parseFlightString } from "src/utils/helps";
import { useSearchParams } from "react-router-dom";
import condition from 'src/utils/condition.json'

import "../styles/flight-detail.scss";

const detailHeader = ["Hành khách", "Số lượng", "Giá vé", "Thuế và phí", "Tổng tiền"];

interface Props {
  flight: FlightOption;
}

export default function FlightDetail({ flight }: Props) {
  const [searchParams] = useSearchParams();

  const request = searchParams.get("Request");
  const airline = searchParams.get("Airline");

  const convertData = useMemo(() => {
    return parseFlightString(request, airline);
  }, [request, airline]);

  const detailData = useMemo(() => {
    const start = domestic?.find((val) => val?.code === flight?.departure?.IATACode);
    const end = domestic?.find((val) => val?.code === flight?.arrival?.IATACode);
    return { departure: { ...flight?.departure, ...start }, arrival: { ...flight?.arrival, ...end } };
  }, [flight]);

  const price = useMemo(() => {
    let data = flight?.fareOptions?.[0];
    flight?.fareOptions?.forEach((val) => {
      if (val?.[FareCode.total] < data?.[FareCode.total]) {
        data = val;
      }
    });
    return data;
  }, [flight]);

  const priceDetail = useMemo(() => {
    const customer = [CustomerKey.adult, CustomerKey.child, CustomerKey.infant];
    const arr = [];
    let priceTicket = 0;
    let taxTicket = 0;
    let total = 0;
    let totalAll = 0;
    customer?.forEach((val) => {
      if (convertData?.[val] < 1) return;
      switch (val) {
        case CustomerKey.child:
          priceTicket = price?.fareChild || 0;
          taxTicket = price?.taxChild || 0;
          total = price?.totalChild || 0;
          totalAll += price?.totalChild;
          break;
        case CustomerKey.infant:
          priceTicket = price?.fareInfant || 0;
          taxTicket = price?.taxInfant || 0;
          total = price?.totalInfant || 0;
          totalAll += price?.totalInfant;
          break;

        default:
          priceTicket = price?.fareAdult || 0;
          taxTicket = price?.taxAdult || 0;
          total = price?.totalAdult || 0;
          totalAll += price?.totalAdult;
          break;
      }
      arr.push({
        name: val,
        quantity: convertData?.[val] || 0,
        priceTicket,
        taxTicket,
        total,
        ...price,
      });
    });
    return { value: arr, totalAll };
    // eslint-disable-next-line
  }, [convertData, price, CustomerKey]);

  const conditionFlight = useMemo(() => {
    const data = condition?.[flight?.airline]?.[flight?.bookingClass]?.condition
    return { label: data, keys: isEmpty(data) ? [] : Object.keys(data) }
  }, [flight])


  return (
    <div className='flight-details-container'>
      {/* Chi tiết chuyến bay */}
      <div className='section'>
        <div
          style={{
            display: "flex",
            borderBottom: `1px solid var(--light-2)`,
            alignItems: "center",
            gap: "4px",
            padding: "10px 0 3px",
            marginBottom: "10px",
          }}
        >
          <Iconify icon='akar-icons:info-fill' />
          <Typography variant='subtitle1'>Chi tiết chuyến bay</Typography>
        </div>
        <div className='details-box'>
          <ul className='flight-info'>
            <li className='flight-info-item'>
              <img src={AirlineImage?.[flight?.airline]} alt={flight?.airline} />
            </li>
            <li>
              <Typography>{`${detailData?.departure?.city} (${detailData?.departure?.code})`}</Typography>
              <Typography>{detailData?.departure?.name || ""}</Typography>
              <Typography>{`Cất cánh: ${convertTime(detailData?.departure?.at, FormatTime.HHMM)}`}</Typography>
              <Typography>{`Ngày: ${convertTime(detailData?.departure?.at, FormatTime.ddmmyyyy)}`}</Typography>
            </li>
            <li>
              <Typography>{`${detailData?.arrival?.city} (${detailData?.arrival?.code})`}</Typography>
              <Typography>{detailData?.arrival?.name || ""}</Typography>
              <Typography>{`Hạ cánh: ${convertTime(detailData?.arrival?.at, FormatTime.HHMM)}`}</Typography>
              <Typography>{`Ngày: ${convertTime(detailData?.arrival?.at, FormatTime.ddmmyyyy)}`}</Typography>
            </li>
            <li>
              <Typography> {`Chuyến bay: ${flight?.airline}${flight?.flightNumber}`}</Typography>
              <Typography>{`Thời gian bay: ${calculateTimeDifference(convertTime(new Date(detailData?.departure?.at), FormatTime.HHMM), convertTime(new Date(detailData?.arrival?.at), FormatTime.HHMM))}`}</Typography>
              <Typography>{`Hạng chỗ: ${SeatClass?.[flight?.airline]}`}</Typography>
              <Typography>{`FareBasic: ${price?.fareBasisCode || ""}`}</Typography>
              <Typography>{`Máy bay: ${Airline?.[flight?.airline] || ""}`}</Typography>
              <Typography>Hành lý xách tay: {conditionFlight?.label?.["Hành lý xách tay"] || ''}</Typography>
              <Typography>Hành lý ký gửi: {conditionFlight?.label?.["Hành lý ký gửi"] || ''}</Typography>
            </li>
          </ul>
        </div>
      </div>

      {/* Chi tiết giá vé */}
      <div className='section'>
        <div
          style={{
            display: "flex",
            borderBottom: `1px solid var(--light-2)`,
            alignItems: "center",
            gap: "4px",
            padding: "10px 0 3px",
            marginBottom: "10px",
          }}
        >
          <Iconify icon='solar:eye-bold' />
          <Typography variant='subtitle1'>Chi tiết giá vé</Typography>
        </div>

        <ul className='pricing-table-header'>
          {detailHeader?.map((val, idx) => (
            <li key={idx}>
              <Typography variant="caption" style={{ fontWeight: 500 }}>{val}</Typography>
            </li>
          ))}
        </ul>
        {priceDetail?.value?.map((val, idx) => {
          return (
            <ul key={idx} className='pricing-table-header'>
              <li>
                <Typography>{Customer?.[val?.name] || ""}</Typography>
              </li>
              <li>
                <Typography>{val?.quantity || ""}</Typography>
              </li>
              <li>
                <Typography>{`${convertToCommasFormat(val?.priceTicket || 0)}`}</Typography>
              </li>
              <li>
                <Typography>{`${convertToCommasFormat(val?.taxTicket || 0)}`}</Typography>
              </li>
              <li>
                <Typography>{`${convertToCommasFormat(val?.total || 0)}`}</Typography>
              </li>
            </ul>
          );
        })}

        <div className='total-cost'>
          <Typography variant='h6'>Tổng chi phí (VND):</Typography>
          <Typography variant='h6'>{`${convertToCommasFormat(priceDetail?.totalAll || 0)}`}</Typography>
        </div>
      </div>

      {/* Điều kiện giá vé */}
      <div className='section'>
        <div
          style={{
            display: "flex",
            borderBottom: `1px solid var(--light-2)`,
            alignItems: "center",
            gap: "4px",
            padding: "10px 0 3px",
            marginBottom: "10px",
          }}
        >
          <Iconify icon='ion:ticket' />
          <Typography variant='subtitle1'>Điều kiện giá vé</Typography>
        </div>
        <ul style={{ display: 'grid' }}>
          {conditionFlight?.keys?.map((val, idx) => (
            <li key={idx}>
              <Typography>* {conditionFlight?.label?.[val] || ''}</Typography>
            </li>

          ))}

        </ul>
      </div>
    </div>
  );
}
