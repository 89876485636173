import "./drawer.scss";

const Drawer = ({ isOpen, onClose, children }) => {
  return (
    <div className={`drawer-overlay ${isOpen ? "open" : ""}`} onClick={onClose}>
      <div className={`drawer-container ${isOpen ? "open" : ""}`} onClick={(e) => e.stopPropagation()}>
        <button className='drawer-close-button' onClick={onClose}>
          &times;
        </button>
        <div className='drawer-content'>{children}</div>
      </div>
    </div>
  );
};

export default Drawer;
