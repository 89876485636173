import { useEffect } from "react";
import Drawer from "src/components/specific/drawer";
import { useBoolean } from "src/hooks/use-boolean";
import { BreakPoint } from "src/utils/enums";
import DisplayMode from "./display-mode";
import FlightFilter from "./flight-filter";
import Iconify from "src/components/specific/iconify";
import Typography from "src/components/common/typography";

import "../styles/flight-filter.scss";

export default function FlightFilterMob() {
  const drawer = useBoolean();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > BreakPoint.lg) {
        drawer.onFalse();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='FlightFilterMob' className='filter-mob-container'>
      <button className='filter-mob-button' onClick={drawer.onTrue}>
        <Iconify icon='mdi:filter' />
        <Typography variant='button'>Lọc chuyến bay</Typography>
      </button>
      <Drawer isOpen={drawer.value} onClose={drawer.onFalse}>
        {drawer.value && (
          <>
            <DisplayMode />
            <FlightFilter />
          </>
        )}
      </Drawer>
    </div>
  );
}
