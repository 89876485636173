import { useCallback } from "react";
import Typography from "src/components/common/typography";
import Iconify from "src/components/specific/iconify";
import { useFlightContext } from "src/context/flight-context";
import { FareCode } from "src/utils/enums";

const radioData = [
  { label: "Giá vé cơ bản cho người lớn", value: FareCode.fare },
  { label: "Giá vé bao gồm thuế và phí", value: FareCode.total },
];

export default function DisplayMode() {
  const { filter, setFilter } = useFlightContext();

  const handleChangeDisplayMode = useCallback(
    (value) => {
      setFilter({ ...filter, displayMode: value });
    },
    [filter, setFilter]
  );

  return (
    <div id='DisplayMode' style={{ display: "grid", boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.19)" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          color: "var(--white)",
          background: "var(--green)",
          padding: "8px 10px",
        }}
      >
        <Iconify icon='solar:eye-bold' />
        <Typography variant='body1'>Chế độ hiển thị theo</Typography>
      </div>
      <ul style={{ padding: "12px 0px", background: "var(--white)", display: "grid" }}>
        {radioData?.map((val: { label: string; value: string }, idx: number) => {
          const isCheck = filter?.displayMode === val?.value;
          return (
            <li
              key={idx}
              style={{
                padding: "8px 10px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                borderTop: idx ? "1px dotted var(--grey-1)" : "unset",
              }}
            >
              <input
                type='radio'
                checked={isCheck}
                style={{ margin: 0, cursor: "pointer" }}
                onChange={() => handleChangeDisplayMode(val?.value)}
              />
              <Typography>{val?.label || ""}</Typography>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
