import Typography from "src/components/common/typography";
import Iconify from "src/components/specific/iconify";
import {
  convertTime,
  getDateRangeFromInput,
  isEmpty,
  updateFlightDateWithExtras,
  uppercaseString,
} from "src/utils/helps";
import { FormatTime } from "src/utils/enums";
import FlightListItem from "./flight-list-item";
import { useCallback, useMemo, useState } from "react";
import domestic from "src/utils/domestic.json";
import { FlightOption } from "src/interfaces/book-flight-ticket-interface";
import { useFlightContext } from "src/context/flight-context";

import "../styles/flight-list.scss";

const header = ["Hàng không", "Giờ khởi hành", "Thời gian bay", "Giờ hạ cánh", "Giá chuyến bay"];
const headerMob = ["Hàng", "Giờ bay", "Tổng giờ", "Giờ hạ", "Giá"];

interface Props {
  flightData: FlightOption[];
  flight: {
    departDate?: string;
    returnDate?: string;
    endPoint?: string;
    startPoint?: string;
    arrival?: string;
  };
  handleSelectFlight?: (value) => void;
}

const FlightList = ({ flightData, flight, handleSelectFlight }: Props) => {
  const { filter, request, airline } = useFlightContext();
  const [dateDefault, setDateDefault] = useState(new Date(flight?.departDate));

  const dateArr = useMemo(() => {
    return getDateRangeFromInput(dateDefault);
  }, [dateDefault]);

  const textFlight = useMemo(() => {
    const start = domestic?.find((val) => val?.code === flight?.startPoint);
    const end = domestic?.find((val) => val?.code === flight?.endPoint);
    return `${start?.city || ""} (${start?.code || ""}) ➞ ${end?.city || ""} (${end?.code || ""})`;
  }, [flight]);

  const handleClickDate = useCallback(
    (date: string) => {
      try {
        setDateDefault(new Date(date));
        window.location.replace(
          `?Request=${updateFlightDateWithExtras(request, flight?.arrival ? "arrival" : "departure", convertTime(new Date(date), FormatTime.ddmmyyyy)?.split("-").join(""))}${airline ? `&Airline=${airline}` : ""}`
        );
      } catch (error) {
        console.log("Error", error);
      }
    },
    [request, flight, airline]
  );

  return (
    <div id='FlightList' className='flight-list-container'>
      <div className='header'>
        <div className='plane-wrapper'>
          <Iconify icon='fa:plane' style={{ width: 23, height: 29 }} />
        </div>
        <div className='header-content'>
          <Typography className='header-content-first' variant='subtitle1'>
            {textFlight}
          </Typography>
          <Typography className='header-content-second'>
            {`${uppercaseString(convertTime(flight?.departDate, FormatTime.DDDD))}, ${convertTime(flight?.departDate, FormatTime.ddmmyyyy)}`}
          </Typography>
        </div>
      </div>

      <ul className='date-picker'>
        {dateArr?.map((val, idx) => (
          <li
            key={idx}
            onClick={() => handleClickDate(val)}
            className={`date-picker-item ${idx === 3 ? "active" : ""}`}
          >
            <Typography>{convertTime(val, FormatTime.ddmmyyyy)}</Typography>
            <Typography variant='body2' style={{ fontWeight: 700 }}>
              {uppercaseString(convertTime(val, FormatTime.DDDD))}
            </Typography>
          </li>
        ))}
      </ul>

      <ul className='table-header'>
        {[...header, ""]?.map((val: string, idx: number) => (
          <li key={idx} className={`table-header-item`}>
            <Typography style={{ fontWeight: 500 }}>{val}</Typography>
          </li>
        ))}
        {[...headerMob]?.map((val: string, idx: number) => (
          <li key={idx} className={`table-header-item-mob`}>
            <Typography style={{ fontWeight: 500 }}>{val}</Typography>
          </li>
        ))}
      </ul>

      {isEmpty(flightData) ? (
        <div style={{ minHeight: 100, display: "grid", placeContent: "center" }}>
          <Typography variant='subtitle1' style={{ color: "var(--orange)" }}>
            Chúng tôi không tìm thấy chuyến bay nào theo yêu cầu
          </Typography>
        </div>
      ) : (
        <div className='table-body'>
          {flightData?.map((flight: FlightOption, idx: number) => (
            <FlightListItem key={idx} filter={filter} flight={flight} handleSelectFlight={handleSelectFlight} />
          ))}
        </div>
      )}
    </div>
  );
};

export default FlightList;
