import "./loading.scss";

const Loading = () => {
  return (
    <>
      <section className='dots-container'>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
      </section>
    </>
  );
};

export default Loading;
