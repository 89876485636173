export enum ModalComponentName {
  ADD_PG = "ADD_PG",
}

export enum BreakPoint {
  // extra-small
  xs = 0,
  // small
  sm = 576,
  //= medium
  md = 768,
  //= large
  lg = 992,
  //= extra-large
  xl = 1200,
}

export enum FormatTime {
  DDMMYYYY = "DD/MM/YYYY",
  ddmmyyyy = "DD-MM-YYYY",
  ddmm = "DD-MM",
  DDDD = "dddd",
  HHMM = "HH:mm",
  DDMMYYYYHHMM = "YYYY-MM-DD HH:mm",
}

export enum AirlineImage {
  VN = "https://plugin.datacom.vn//Resources/Images/Airline/VN.gif", //Vietnam Airlines
  VJ = "https://plugin.datacom.vn//Resources/Images/Airline/VJ.gif", //VietJet Air
  QH = "https://plugin.datacom.vn//Resources/Images/Airline/QH.gif", //Bamboo Airways
  BL = "https://plugin.datacom.vn//Resources/Images/Airline/BL.gif", //Pacific Airlines
  VU = "https://plugin.datacom.vn//Resources/Images/Airline/VU.gif", //Vietravel Airlines
}

export enum FareCode {
  total = "totalAdult",
  fare = "fareAdult",
}

export enum SeatClass {
  VN = "L", //Vietnam Airlines
  VJ = "B1_ECO", //VietJet Air
  QH = "L", //Bamboo Airways
  BL = "S", //Pacific Airlines
  VU = "O", //Vietravel Airlines
}

export enum Airline {
  VN = "V322", //Vietnam Airlines
  VJ = "J333", //VietJet Air
  QH = "A321-100/200", //Bamboo Airways
  BL = "P123", //Pacific Airlines
  VU = "A223", //Vietravel Airlines
}

export enum AirlineName {
  VN = "Vietnam Airlines", //Vietnam Airlines
  VJ = "VietJet Air", //VietJet Air
  QH = "Bamboo Airways", //Bamboo Airways
  BL = "Pacific Airlines", //Pacific Airlines
  VU = "Vietravel Airlines", //Vietravel Airlines
}

export enum CustomerKey {
  adult = "adult",
  child = "child",
  infant = "infant",
}

export enum Customer {
  adult = "Người lớn",
  child = "Trẻ em",
  infant = "Em bé",
}

export enum TitleKey {
  MR = "MR",
  MRS = "MRS",
}

export enum GenderKey {
  MALE = "Nam",
  FEMALE = "Nữ",
}

export enum PaymentMethodKey {
  QR = "QR",
  OFFICE = "OFFICE",
}

export enum PaymentMethod {
  QR = "Chuyển khoản ngân hàng",
  OFFICE = "Thanh toán tại văn phòng",
}

export enum BagValue {
  "Bag 20kgs" = "Gói hành lý 20kg ",
  "Bag 30kgs" = "Gói hành lý 30kg ",
  "Bag 40kgs" = "Gói hành lý 40kg ",
  "Bag 50kgs" = "Gói hành lý 50kg ",
  "Bag 60kgs" = "Gói hành lý 60kg ",
  "Bag 70kgs" = "Gói hành lý 70kg ",
  "Oversize 20kgs" = "Kiện HLQK 20kg ",
  "Oversize 30kgs" = "Kiện HLQK 30kg ",
  "Cabin Extra 2k" = "2kg xách tay bổ sung ",
  //VU
  "APBG" = "Hành lý mua trước 15kg/1 kiện",
  "BPBG" = "Hành lý mua trước 23kg/1 kiện",
  "CPBG" = "Hành lý mua trước 32kg/1 kiện",
}
