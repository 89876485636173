import Typography from "src/components/common/typography";
import Iconify from "src/components/specific/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import { useMemo } from "react";
import { AirlineImage, AirlineName, FareCode, FormatTime } from "src/utils/enums";
import { FlightOption } from "src/interfaces/book-flight-ticket-interface";
import domestic from "src/utils/domestic.json";
import { convertTime, convertToCommasFormat } from "src/utils/helps";
import FlightDetail from "./flight-detail";

import "../styles/flight-selection.scss";

interface Props {
  handleSelectAgain: (index) => void
  selectedFlight?: FlightOption[]
}


const FlightSelection = ({ selectedFlight, handleSelectAgain }: Props) => {
  return (
    <div className='flight-selection'>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          background: "var(--light-2)",
          padding: "8px 10px",
          borderLeft: "5px solid var(--green)",
        }}
      >
        <Iconify icon='fa:plane' />
        <Typography variant='body1'>Chuyến bay bạn đã lựa chọn</Typography>
      </div>
      <div className='flight-selection__list'>{selectedFlight?.map((val: FlightOption, idx: number) => <FlightCard flight={val} key={idx} index={idx} handleSelectAgain={handleSelectAgain} />)}</div>
    </div>
  );
};

export default FlightSelection;

const FlightCard = ({ flight, index, handleSelectAgain }: { flight: FlightOption, index: number, handleSelectAgain: (index) => void }) => {
  const showDetail = useBoolean();

  const price = useMemo(() => {
    let data = flight?.fareOptions?.[0];
    flight?.fareOptions?.forEach((val) => {
      if (val?.[FareCode.total] < data?.[FareCode.total]) {
        data = val;
      }
    });
    return data;
  }, [flight]);

  const departureData = useMemo(() => {
    const city = domestic?.find((val) => val?.code === flight?.departure?.IATACode)?.city || "";
    const date = convertTime(flight?.departure?.at, FormatTime.ddmmyyyy);
    const time = convertTime(flight?.departure?.at, FormatTime.HHMM);
    return { city, date, time };
  }, [flight?.departure]);

  const arrivalData = useMemo(() => {
    const city = domestic?.find((val) => val?.code === flight?.arrival?.IATACode)?.city || "";
    const date = convertTime(flight?.arrival?.at, FormatTime.ddmmyyyy);
    const time = convertTime(flight?.arrival?.at, FormatTime.HHMM);
    return { city, date, time };
  }, [flight?.arrival]);

  return (
    <>
      <div className='flight-card_wrapper'>
        <div className='flight-card'>
          <div className='flight-card__left'>
            <img
              src={AirlineImage?.[flight?.airline]}
              alt={AirlineName?.[flight?.airline]}
              className='flight-card__logo'
            />
            <div>
              <Typography variant='caption'>{AirlineName?.[flight?.airline]}</Typography>
            </div>
          </div>

          <div className='flight-card__details'>
            <Typography variant='body1'>{departureData?.city}</Typography>
            <Typography variant='subtitle1'>{departureData?.time}</Typography>
            <Typography variant='body1'>{departureData?.date}</Typography>
          </div>

          <div className='flight-card__code'>
            <div style={{ display: "flex", alignItems: "center", gap: "4px", color: "var(--green)" }}>
              <Iconify icon='fa:plane' style={{ width: 10, height: 9 }} />
              <Typography
                style={{ fontWeight: 500, color: "var(--green)" }}
                variant='caption'
              >{`${flight?.airline}${flight?.flightNumber}`}</Typography>
            </div>

            <div className='flight-line'>
              <div className='start-point'></div>
              <div className='plane-icon'>✈</div>
            </div>

            <div className='flight-detail-button' onClick={showDetail.onToggle}>
              <Typography>{showDetail.value ? "Ẩn" : "Chi tiết"}</Typography>
              <Iconify icon='solar:alt-arrow-down-linear' style={{ transform: showDetail.value ? 'rotate(180deg)' : 'unset' }} />
            </div>
          </div>

          <div className='flight-card__details'>
            <Typography variant='body1'>{arrivalData?.city}</Typography>
            <Typography variant='subtitle1'>{arrivalData?.time}</Typography>
            <Typography variant='body1'>{arrivalData?.date}</Typography>
          </div>

          <div className='flight-card__price'>
            <Typography
              variant='subtitle1'
              style={{ color: "var(--orange)" }}
            >{`${convertToCommasFormat(price?.[FareCode?.total] || 0)} VND`}</Typography>
            <button className='flight-card__button' onClick={() => handleSelectAgain(index)}>
              <Typography variant='button'>Chọn lại</Typography>
              <Iconify icon='simple-line-icons:reload' />
            </button>
          </div>
        </div>
        <div className='flight-card__mob'>
          <div className='flight-card__left'>
            <img src={AirlineImage?.[flight?.airline]} alt={flight?.airline} className='flight-card__logo' />
          </div>
          <div className='flight-card__price'>
            <Typography
              variant='subtitle1'
              style={{ color: "var(--orange)" }}
            >{`${convertToCommasFormat(price?.[FareCode?.total] || 0)} VND`}</Typography>
          </div>
          <div>
            <button className='flight-card__button' onClick={() => handleSelectAgain(index)}>
              <Typography variant='button'>Chọn lại</Typography>
              <Iconify icon='simple-line-icons:reload' />
            </button>
          </div>
        </div>

        {showDetail.value && <FlightDetail flight={flight} />}

      </div>
    </>
  );
};
