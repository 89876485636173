import { typography } from "src/theme/typography";

interface Props {
  variant?:
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "overline"
  | "button";
  style?: React.CSSProperties;
  children?: any;
  className?: string;
  onClick?: () => void
}

export default function Typography({ variant = "body2", style, children, className = "", onClick, ...rest }: Props) {
  switch (variant) {
    case "h1":
      return (
        <h1 style={{ ...typography.h1, ...style }} className={className} onClick={onClick} {...rest}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 style={{ ...typography.h2, ...style }} className={className} onClick={onClick} {...rest}>
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3 style={{ ...typography.h3, ...style }} className={className} onClick={onClick} {...rest}>
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4 style={{ ...typography.h4, ...style }} className={className} onClick={onClick} {...rest}>
          {children}
        </h4>
      );
    case "h5":
      return (
        <h5 style={{ ...typography.h5, ...style }} className={className} onClick={onClick} {...rest}>
          {children}
        </h5>
      );
    case "h6":
      return (
        <h6 style={{ ...typography.h6, ...style }} className={className} onClick={onClick} {...rest}>
          {children}
        </h6>
      );
    case "subtitle1":
      return (
        <p style={{ ...typography.subtitle1, ...style }} className={className} onClick={onClick} {...rest}>
          {children}
        </p>
      );
    case "subtitle2":
      return (
        <p style={{ ...typography.subtitle1, ...style }} className={className} onClick={onClick} {...rest}>
          {children}
        </p>
      );
    case "body1":
      return (
        <p style={{ ...typography.body1, ...style }} className={className} onClick={onClick} {...rest}>
          {children}
        </p>
      );
    case "caption":
      return (
        <p style={{ ...typography.caption, ...style }} className={className} onClick={onClick} {...rest}>
          {children}
        </p>
      );

    default:
    case "body2":
      return (
        <p style={{ ...typography.body2, ...style }} className={className} onClick={onClick} {...rest}>
          {children}
        </p>
      );
  }
}
