import { useMemo } from 'react';
import { SettingsValueProps } from '../types';
import { SettingsContext } from './settings-context';
import { useLocalStorage } from 'src/hooks/use-local-storage';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'settings';

type SettingsProviderProps = {
  children: React.ReactNode;
  defaultSettings: SettingsValueProps;
};

export function SettingsProvider({ children, defaultSettings }: SettingsProviderProps) {
  const { state } = useLocalStorage(STORAGE_KEY, defaultSettings);

  const memoizedValue = useMemo(
    () => ({
      ...state,
    }), [state]);

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}
