import { useCallback, useEffect, useMemo } from "react";
import Iconify from "src/components/specific/iconify";
import Typography from "src/components/common/typography";
import { convertTextFlight, convertTime, convertToCommasFormat, isEmpty } from "src/utils/helps";
import { AirlineImage, BagValue, Customer, CustomerKey, FormatTime, GenderKey, TitleKey } from "src/utils/enums";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { SessionForm, TypedBagItem } from "src/interfaces/book-flight-ticket-interface";

import "../styles/form-info.scss";

interface Props {
  registerForm: UseFormRegister<any>;
  sessionData: SessionForm;
  setValue: UseFormSetValue<any>;
  errors: any;
  bagOptions: any;
}

const header = [
  {
    label: "Hành khách",
  },
  {
    label: "Giới tính",
    isRequire: true,
  },
  {
    label: "Họ",
    isRequire: true,
  },
  {
    label: "Điệm & tên",
    isRequire: true,
  },
  {
    label: "Ngày sinh",
    isRequire: true,
  },
];

const FormInfo = ({ registerForm, setValue, errors, sessionData, bagOptions }: Props) => {
  const noteData = useMemo(() => {
    const dateCheck = new Date(sessionData?.flights?.[0]?.returnDate || sessionData?.flights?.[0]?.departDate);

    const date2YearsAgo = new Date(dateCheck);
    date2YearsAgo.setFullYear(dateCheck.getFullYear() - 2);

    const dateYearsAgo = new Date(dateCheck);
    dateYearsAgo.setFullYear(dateCheck.getFullYear() - 2);
    // dateYearsAgo.setMonth(dateYearsAgo.getMonth() - 1);

    const pastDate = new Date(date2YearsAgo);
    pastDate.setFullYear(pastDate.getFullYear() - 10);
    // pastDate.setMonth(pastDate.getMonth() - 1);

    return [
      {
        icon: <Iconify icon='fluent:warning-16-filled' style={{ width: 25, height: 25 }} />,
        label: "Lưu ý về độ tuổi người lớn, trẻ em, trẻ sơ sinh",
      },
      {
        icon: <Iconify icon='fa-regular:hand-point-right' />,
        label: "Người lớn từ 12 tuổi trở lên",
      },
      {
        icon: <Iconify icon='fa-regular:hand-point-right' />,
        label: `Trẻ em có độ tuổi từ 2-12, ngày sinh nằm trong khoảng thời gian từ ${convertTime(pastDate, FormatTime.ddmmyyyy)} đến ${convertTime(date2YearsAgo, FormatTime.ddmmyyyy)}`,
      },
      {
        icon: <Iconify icon='fa-regular:hand-point-right' />,
        label: `Em bé dưới 2 tuổi, ngày sinh nằm trong khoảng thời gian từ ${convertTime(dateYearsAgo, FormatTime.ddmmyyyy)} đến ${convertTime(dateCheck, FormatTime.ddmmyyyy)}`,
      },
      {
        icon: <Iconify icon='fa-regular:hand-point-right' />,
        label: "Nếu trẻ em và em bé của bạn không sinh vào khoảng thời gian trên, vui lòng chọn ngày bay khác",
      },
      {
        icon: <Iconify icon='fluent:warning-16-filled' style={{ width: 25, height: 25 }} />,
        label:
          "Lưu ý: thông tin quốc tịch và nơi cấp quý khách vui lòng nhập dưới dạng mã quốc gia 3 kí tự, ví dụ: VNM, USA, ...",
      },
    ];
  }, [sessionData]);

  const listCustomer = useMemo(() => {
    let adult = Array.from({ length: sessionData?.adult }, (_, index) => index + 1)?.map(() => CustomerKey.adult);
    let child = Array.from({ length: sessionData?.child }, (_, index) => index + 1)?.map(() => CustomerKey.child);
    let infant = Array.from({ length: sessionData?.infant }, (_, index) => index + 1)?.map(() => CustomerKey.infant);

    return [...adult, ...child, ...infant];
  }, [sessionData]);

  const validateDOB = useCallback(
    (dob: string, type: string) => {
      const flightDate = new Date(sessionData?.flights?.[0]?.returnDate || sessionData?.flights?.[0]?.departDate);
      const birthDate = new Date(dob);
      const adultMinDate = new Date(flightDate);
      adultMinDate.setFullYear(flightDate.getFullYear() - 12);

      const childMinDate = new Date(flightDate);
      childMinDate.setFullYear(flightDate.getFullYear() - 12);

      const childMaxDate = new Date(flightDate);
      childMaxDate.setFullYear(flightDate.getFullYear() - 2);

      const infantMinDate = new Date(flightDate);
      infantMinDate.setFullYear(flightDate.getFullYear() - 2);

      const infantMaxDate = new Date(flightDate);

      if (type === CustomerKey.adult && birthDate <= adultMinDate) return true; // Người lớn
      if (type === CustomerKey.child && birthDate >= childMinDate && birthDate < childMaxDate) return true; // Trẻ em
      if (type === CustomerKey.infant && birthDate >= infantMinDate && birthDate <= infantMaxDate) return true; // Em bé

      return `Ngày sinh không hợp lệ cho hành khách: ${Customer?.[type]}`;
    },
    [sessionData]
  );

  useEffect(() => {
    if (isEmpty(listCustomer)) return;
    listCustomer?.forEach((val, index) => {
      setValue(`paxLists.${index}.paxType`, val?.toUpperCase());
      setValue(`paxLists.${index}.paxId`, `${index + 1}`);
    });
  }, [listCustomer, setValue]);

  return (
    <div className='customer-info'>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          background: "var(--light-2)",
          padding: "8px 10px",
          borderLeft: "5px solid var(--green)",
        }}
      >
        <Iconify icon='heroicons:user-group-16-solid' />
        <Typography variant='body1'>Thông tin khách hàng</Typography>
      </div>
      <div className='form-group'>
        <ul className='form-group__header'>
          {header?.map((val, idx) => (
            <li key={idx}>
              <Typography variant='subtitle1'>{val?.label || ""}</Typography>
              {val?.isRequire && <Typography style={{ color: "var(--danger)" }}>*</Typography>}
            </li>
          ))}
        </ul>

        <ul className='form-group__item'>
          {!isEmpty(listCustomer) &&
            listCustomer?.map((val, idx) => (
              <li style={{ display: "grid", gap: "10px" }} key={`form_item_${idx}`}>
                <div className='form-group__item-info-base'>
                  <div className={`form-customer__label ${val}`}>
                    <Typography>{`${Customer?.[val]} ${idx + 1}`}</Typography>
                  </div>
                  <div className='form-field'>
                    <select required {...registerForm(`paxLists.${idx}.title`)}>
                      <option value={TitleKey.MR}>Ông</option>
                      <option value={TitleKey.MRS}>Bà</option>
                    </select>
                  </div>
                  <div className='form-field'>
                    <input
                      type='text'
                      required
                      placeholder='Nhập họ'
                      {...registerForm(`paxLists.${idx}.lastName`, { required: true })}
                    />
                  </div>

                  <div className='form-field'>
                    <input
                      type='text'
                      required
                      placeholder='Nhập đệm & tên'
                      {...registerForm(`paxLists.${idx}.firstName`, { required: true })}
                    />
                  </div>

                  <div className='form-field'>
                    <input
                      required
                      type='date'
                      {...registerForm(`paxLists.${idx}.birthday`, {
                        required: true,
                        validate: (value) => validateDOB(value, val),
                      })}
                    />
                    {errors.paxLists?.[idx]?.birthday && (
                      <Typography variant='caption' style={{ color: "red" }}>
                        {errors.paxLists?.[idx]?.birthday?.message}
                      </Typography>
                    )}
                  </div>
                </div>

                {!isEmpty(bagOptions) &&
                  val !== CustomerKey.infant &&
                  Object?.keys(bagOptions)?.map((val: string, index: number) => {
                    const data = bagOptions?.[val];
                    const flight = data?.[0]?.flights?.split("-");
                    const start = flight?.[0];
                    const end = flight?.[1];

                    if (isEmpty(data)) return <></>;
                    return (
                      <div key={index} className='form-group__item-info-bag'>
                        <img
                          src={AirlineImage?.[data?.[0]?.airline]}
                          alt='avatar'
                          style={{
                            maxWidth: "80px",
                          }}
                        />
                        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                          <Typography style={{ fontWeight: 500 }}>Hành lý ký gửi: </Typography>
                          <Typography>{convertTextFlight(start, end, false)}</Typography>
                        </div>

                        <div className='form-field'>
                          <select {...registerForm(`paxLists.${idx}.ancillaryCode.${val}`)}>
                            <option value={""}>Chọn hành lý ký gửi</option>
                            {!isEmpty(data) &&
                              data?.map((item: TypedBagItem, indexNumber: number) => (
                                <option key={`options-${indexNumber}`} value={item?.code}>
                                  {BagValue?.[item?.code]}: {convertToCommasFormat(item?.fares?.[0]?.total || 0)} VND
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    );
                  })}

                {/* {val !== CustomerKey.infant && <div className='form-group__item-info-base-2'>
                <div className='form-field'>
                  <input type='text' placeholder='CCCD/ Hộ chiếu' />
                </div>
                <div className='form-field'>
                  <input type='text' placeholder='Quốc tịch (VD: VNM)' />
                </div>
                <div className='form-field'>
                  <input type='text' placeholder='Nơi cấp (VD: VNM)' />
                </div>
                <div className='form-field'>
                  <input type='text' placeholder='Ngày hết hạn (VD: 31122030)' />
                </div>
              </div>} */}
              </li>
            ))}
        </ul>
      </div>
      <div className='note'>
        {noteData?.map((val, idx) => (
          <div
            key={`note_${idx}`}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              color: !idx || idx === noteData?.length - 1 ? "var(--danger)" : "unset",
              marginLeft: !idx || idx === noteData?.length - 1 ? "unset" : "10px",
            }}
          >
            {val?.icon}
            <Typography>{val?.label}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormInfo;
