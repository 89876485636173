import Typography from "src/components/common/typography";
import { memo } from "react";

import "../styles/step-progress.scss";

interface Props {
  steps: string[];
  currentStep: number;
}

const StepProgress = ({ steps, currentStep }: Props) => {
  return (
    <div className='step-progress-container'>
      <ul className='step-progress-ul'>
        {steps.map((step: string, index: number) => (
          <li
            key={index}
            className={`step ${index + 1 === currentStep ? "active" : ""} ${index + 1 < currentStep ? "completed" : ""}`}
          >
            {index <= steps.length - 1 && index > 0 && (
              <div className={`step-connector ${index < currentStep + 1 ? "active" : ""}`}></div>
            )}
            <Typography variant='caption' className='step-number'>
              {index + 1}
            </Typography>
            <Typography variant='body1' className='step-title'>
              {step}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(StepProgress);
