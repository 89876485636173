import { useContext, createContext } from "react";

// ----------------------------------------------------------------------

export const FlightContext = createContext({} as any);

export const useFlightContext = () => {
  const context = useContext(FlightContext);

  if (!context) throw new Error("useSettingsContext must be use inside");

  return context;
};
