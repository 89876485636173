import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <div id='PageNotFound'>
        <p>Sorry, the page you visited does not exist.</p>
        <button onClick={() => navigate('/auth/login')}>
          Back Home
        </button>

      </div>
    </>
  );
};

export default PageNotFound;
