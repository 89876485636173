import Typography from "src/components/common/typography";
import Iconify from "src/components/specific/iconify";
import { convertToCommasFormat, isEmpty } from "src/utils/helps";
import { useBoolean } from "src/hooks/use-boolean";
import Drawer from "src/components/specific/drawer";
import PriceSummary from "./price-summary";
import { useFlightContext } from "src/context/flight-context";
import { useMemo } from "react";

import "../styles/info-flight-icon.scss";

export default function InfoFlightIcon() {
  const drawer = useBoolean();
  const { priceFlightData, watch, bagOptions } = useFlightContext();

  const values = watch();

  const convertBagData = useMemo(() => {
    let data = [];
    values?.paxLists?.forEach((val) => {
      const ancillaryCode = val?.ancillaryCode || {};
      Object.keys(ancillaryCode)?.forEach((value) => {
        if (isEmpty(ancillaryCode?.[value])) return;
        const bagData = bagOptions?.[value];
        data?.push(bagData?.find((item) => item?.code === ancillaryCode?.[value]));
      });
    });
    return data;
  }, [values, bagOptions]);

  const price = useMemo(() => {
    let price = priceFlightData?.all;
    if (isEmpty(convertBagData)) return price;
    convertBagData?.forEach((val) => {
      price += val?.fares?.[0]?.total;
    });
    return price;
  }, [priceFlightData, convertBagData]);

  return (
    <>
      <div id='InfoFlightIcon' className='card-mob' onClick={drawer.onTrue}>
        <div className='card-mob__icon'>
          <Iconify icon='mdi:cart' />
        </div>
        <div style={{ display: "grid", alignItems: "center" }}>
          <Typography variant='caption'>Chuyến bay của bạn</Typography>
          <Typography variant='caption'>{`${convertToCommasFormat(price || 0)} VND`}</Typography>
        </div>
      </div>

      <Drawer isOpen={drawer.value} onClose={drawer.onFalse}>
        {drawer.value && <PriceSummary />}
      </Drawer>
    </>
  );
}
