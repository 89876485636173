// ----------------------------------------------------------------------

import { FlightContext } from "./flight-context";

type FlightProviderProps = {
  children: React.ReactNode;
  values: any;
};

export function FlightProvider({ children, values }: FlightProviderProps) {
  return <FlightContext.Provider value={{ ...values }}>{children}</FlightContext.Provider>;
}
