import { forwardRef } from "react";
import { Icon } from "@iconify/react";

import { IconifyProps } from "./types";

// ----------------------------------------------------------------------

interface Props {
  icon: IconifyProps;
  width?: number;
  style?: React.CSSProperties;
}

const Iconify = forwardRef<SVGElement, Props>(({ icon, width = 20, style, ...other }, ref: any) => (
  <Icon ref={ref} className='component-iconify' icon={icon} style={{ width, height: width, ...style }} {...other} />
));

export default Iconify;
