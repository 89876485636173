import { useCallback, useMemo } from "react";
import Iconify from "src/components/specific/iconify";
import Typography from "src/components/common/typography";
import TimeRangeSlider from "src/components/specific/range-slider";
import { AirlineName } from "src/utils/enums";
import { convertNumber } from "src/utils/helps";
import { useFlightContext } from "src/context/flight-context";

import "../styles/flight-filter.scss";

const sortAll = "all";

const FlightFilter = () => {
  const { filter, setFilter, listAirline } = useFlightContext();

  const airlines = useMemo(() => {
    const data = [{ code: sortAll, name: "Tất cả", price: null }];
    listAirline?.forEach((val) => {
      data.push({ code: val?.code, name: AirlineName?.[val?.code] || "", price: val?.lowestPrice || 0 });
    });

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAirline]);

  const handleAirlineToggle = useCallback(
    (code: string) => {
      let filterData = [];
      if (code === sortAll) {
        filterData = filter?.sortByAirline?.length === airlines?.length ? [] : airlines?.map((a: any) => a?.code);
      } else {
        filterData = filter?.sortByAirline.includes(code)
          ? filter?.sortByAirline?.filter((airline) => airline !== code && airline !== sortAll)
          : filter?.sortByAirline?.length === listAirline?.length - 1
            ? airlines?.map((a: any) => a?.code)
            : [...filter?.sortByAirline, code];
      }
      setFilter({ ...filter, sortByAirline: [...filterData] });
    },
    [airlines, filter, listAirline?.length, setFilter]
  );

  const handleRangeChange = useCallback((e, values, setRange) => {
    const { name, value } = e.target;
    const newRange = [...values];
    newRange[name === "start" ? 0 : 1] = parseInt(value, 10);
    if (newRange[0] <= newRange[1]) return setRange(newRange);
  }, []);

  return (
    <div id='FlightFilter' className='flight-filter'>
      <div className='filter-header'>
        <Iconify icon='mdi:filter' />
        <Typography variant='body1'>Lọc chuyến bay</Typography>
      </div>

      <div className='filter-section'>
        <div className='section-header'>
          <Typography variant='body1'>Chọn hãng hàng không</Typography>
          <Iconify icon='fa6-solid:plane' style={{ transform: "rotate(180deg)" }} />
        </div>
        <ul className='airline-list'>
          {airlines?.map((airline) => (
            <li key={airline?.code} className='airline-item'>
              <div className='airline-item-1'>
                <input
                  type='checkbox'
                  checked={filter?.sortByAirline.includes(airline?.code)}
                  onChange={() => handleAirlineToggle(airline?.code)}
                  style={{ cursor: "pointer" }}
                />
                <Typography>{airline?.name}</Typography>
              </div>
              {airline?.price && (
                <Typography variant='caption' className='price'>
                  {convertNumber(airline?.price) || ""}
                </Typography>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className='filter-section'>
        <div className='section-header'>
          <Typography variant='body1'>Chọn theo giờ</Typography>
          <Iconify icon='si:clock-fill' />
        </div>
        <div className='time-range'>
          <Typography variant='body1' className='time-range-text'>
            Giờ khởi hành
          </Typography>
          <TimeRangeSlider
            initialValue={[filter?.departureTime?.start, filter?.departureTime?.end]}
            handleRangeChange={(e) =>
              handleRangeChange(e, [filter?.departureTime?.start, filter?.departureTime?.end], (value) =>
                setFilter({ ...filter, departureTime: { start: value?.[0], end: value?.[1] } })
              )
            }
          />
          <Typography variant='subtitle1' style={{ color: "var(--orange)" }} className='time-range-text'>
            {filter?.departureTime?.start}:00 h ➔ {filter?.departureTime?.end}:00 h
          </Typography>
        </div>
        <div className='time-range'>
          <Typography variant='body1' className='time-range-text'>
            Giờ hạ cánh
          </Typography>
          <TimeRangeSlider
            initialValue={[filter?.arrivalTime?.start, filter?.arrivalTime?.end]}
            handleRangeChange={(e) =>
              handleRangeChange(e, [filter?.arrivalTime?.start, filter?.arrivalTime?.end], (value) =>
                setFilter({ ...filter, arrivalTime: { start: value?.[0], end: value?.[1] } })
              )
            }
          />
          <Typography variant='subtitle1' style={{ color: "var(--orange)" }} className='time-range-text'>
            {filter?.arrivalTime?.start}:00 h ➔ {filter?.arrivalTime?.end}:00 h
          </Typography>
        </div>
      </div>

      <div className='filter-section'>
        <div className='section-header'>
          <Typography variant='body1'>Số điểm dừng</Typography>
          <Iconify icon='gridicons:pause' />
        </div>
        <div className='direct-flight'>
          <input
            type='checkbox'
            checked={filter?.directFlight}
            onChange={() => setFilter({ ...filter, directFlight: !filter?.directFlight })}
          />
          <Typography>Chuyến bay thẳng</Typography>
        </div>
      </div>
    </div>
  );
};

export default FlightFilter;
